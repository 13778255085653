.displays-bg-container {
  background-color: #f5f7f8;
  width: 100%;
  padding: 8px 10px;
  min-height: 100vh;
  overflow-y: auto !important;
}

.displays-bg-container::-webkit-scrollbar-track {
  background: transparent;
}

.displays-bg-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
}

.displays-bg-container::-webkit-scrollbar {
  width: 8px;
}
.display-preview-container {
  display: flex;
  gap: 10px;
  width: 100%;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.texas-heading {
  margin-bottom: 0px;
}

/* .Filter-button {
  background-color: white;
  font-size: 14px !important;
  color: rgb(101, 89, 160);
  padding: 0px 10px;
  border-radius: 3px;
  border: 1px solid #b4b3b3;
  text-align: center;
  justify-content: center;
} */

.add-layout-button {
  font-size: 14px !important;
  color: rgb(101, 89, 160);
  border-radius: 3px;
  text-align: center;
  justify-content: center;
  width: 130px;
  background-color: #0d6efd !important;
  padding: 5px;
  color: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 3px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.add-layout-button:hover {
  background-color: rgb(8, 95, 188) !important;
}

.layout-add-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.pagination li {
  border: none;
}

.pagination-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-btn-container {
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
}

.close-btn {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 30px;
  color: #000;
}

.pagination-item {
  margin: 0 3px;
  border-radius: 50%;
  cursor: pointer;
}

.pagination-item.active {
  background-color: #007bff;
  color: #fff;
}

.top-button {
  width: 50px;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
  gap: 3px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 21px;
}

.Filter-button {
  background-color: #0d6efd;
  width: 100px;
  padding: 5px;
  color: #fff;
  display: flex;
  border-radius: 3px;
  justify-content: center;
  border: none;
  align-items: center;
  gap: 3px;
  font-family: "poppins";
  font-size: 14px;
  height: 30px;
}
.cancell-button {
  /* background-color: #0d6efd; */
  width: 100px;
  padding: 5px;
  color: #fff;
  display: flex;
  border-radius: 3px;
  justify-content: center;
  border: none;
  align-items: center;
  gap: 3px;
  font-family: "poppins";
  font-size: 14px;
  height: 30px;
}

.Filter-button:hover {
  background-color: rgb(8, 95, 188) !important;
}

.plus-btn {
  background-color: transparent;
  border: none;
}

.plus-btn:hover {
  background-color: transparent;
}

.monitor-all-btn-container {
  display: flex;
  gap: 10px;
}

.marginbootom {
  margin-bottom: 5px !important;
}

.parabeloww-filter {
  color: #132043;
  margin-top: 10px;
  font-size: 12px;
}

.horizontalline {
  color: #ddd;
}

.card-img-top {
  height: 290px;
  height: 320px;
  margin-bottom: 10px;
  width: 100%;
}
.modal-header {
  align-items: normal;
}
.all-table-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 25px;
  margin-right: 25px;
  /* width: 100%; */
}
.kk {
  color: hsl(0, 11%, 93%);
}

.add-assignment-buttons {
  font-size: 14px !important;
  color: rgb(101, 89, 160);
  border-radius: 3px;
  text-align: center;
  justify-content: center;
  width: 160px;
  background-color: #0d6efd !important;
  padding: 7px;
  color: #fff;
  display: flex;
  align-items: center;
  height: 30px;
  gap: 3px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* font-weight: bold; */
  font-size: 14px;
}
.add-assignment-buttons:hover {
  background-color: rgb(8, 95, 188) !important;
}
.cfvlby {
  min-height: 0px !important;
}
.image-display {
  border: 3px solid black;
  height: 411px !important;
  width: 100%;
}

.cards-image-display {
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 20px;
}

.image-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableright {
  width: 50%;

  width: 40%;
}

.avatar-display {
  width: 45px;
  margin-right: 10px;
  height: 45px;
  border-radius: 50%;
}

.search-container {
  display: flex;
  justify-content: right;
  gap: 9px;
}

.top-avatar-line {
  display: flex;

  align-items: center;
}

.top-avatar-line h5 {
  margin: 0;
  line-height: 1.1;
  font-size: 15px;
}

.top-avatar-line p {
  margin: 0;
  line-height: 1.1;
  font-size: 12px;
}

.spanin-heading {
  color: black;
  font-size: 18px;
  text-decoration: underline;
}

.two-line-clamp {
  font-size: 16px;
}

.highlight {
  color: #000000;
}

.staus-tag {
  background-color: rgb(254, 105, 50);
  padding: 2px;
  margin: 0;
  font-size: 12px;
  max-width: 40px;
  color: white;
  text-align: center;
  border-radius: 2px;
}

.information-food {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.below-line-card-body {
  font-size: 12px;
}

.display-tap {
  align-items: center;
  border-radius: 52%;
  color: #ffffff;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 23px;
  justify-content: center;
  text-align: center;
  width: 31px;
}

.display-tap-yel {
  align-items: center;
  border-radius: 5px;
  color: #000000;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 23px;
  justify-content: center;
  text-align: center;
  width: 91px;
}

.first {
  background-color: green;
}

.second {
  background-color: red;
}

.third {
  background-color: yellow;
}

.light-color {
  font-size: 10px;
  color: rgb(125, 125, 125);
}

.colorred {
  color: red;
}

.last-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconsbottom {
  font-size: 20px;
  display: flex;
  gap: 2px;
}

.pencil {
  color: black;
}

.suitcase {
  color: #727272;
  font-size: 22px;
}

.deletee {
  color: red;
}

.Assign-button {
  background-color: white;
  font-size: 14px;
  color: rgb(101, 89, 160);
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid #b4b3b3;
  text-align: center;
  justify-content: center;
  margin: auto;
}

.plus-button {
  font-size: 38px;
  color: blue;
}

.custom-modal {
  max-width: 650px;
}

.custom-modal-size .modal-dialog {
  max-width: 650px;
  border-radius: 0px !important;
}

.headingmodal {
  padding-left: 10px;
}

.display-assignment-modl {
  text-align: center !important;
}

.display-assignment-modl h6,
.display-assignment-modl p {
  margin: 0px;
  margin-top: 2px;
  font-size: 22px;
}

.display-assignment-modl p {
  font-size: 14px !important;
  color: #727272;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
  background-color: #efe1e1;
}

.body-modal {
  min-height: 60vh;
}

.add-Assign-button {
  font-size: 12px;
  border-radius: 2px;
  margin-bottom: 10px;
}

.modalpic {
  max-width: 200px;
}

.display-pop-container {
  display: flex;
  padding: 0px 10px;
  border: 1px solid #d9d9d9;
  margin: 6px;
  border-radius: 5px;
  box-shadow: 0px 4px 15px -3px rgba(0, 0, 0, 0.1);
  align-items: center;
  gap: 30px;
}

/* CSS */
.display-pop-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  max-height: 500px;
}

/* .dropdown-container {
  margin-bottom: 10px;
} */

@media screen and (min-width: 768px) {
  /* Apply styles for screens wider than 768px */
  .display-pop-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-container {
    width: 45%; /* Adjust as needed */
  }
}

.dropdown-container {
  /* font-family: Arial, sans-serif; */
  position: relative;
  width: 317px;
  padding-top: 20px;
}

.display-layouts-heading {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.modal-footer {
  background-color: #9eb8d9;
}

.dropdown-label {
  position: absolute;
  top: 20;
  left: 10px;
  background-color: #fff;
  padding: 0 4px;
  color: #000;
  font-size: 14px;
  transition: all 0.2s;
  transform: translateY(-50%);
  z-index: 1;
}

.dropdown-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  position: relative;
  z-index: 0;
}
.yes-modal-btn {
  width: 120px;
}
.dropdown-container::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: blue;
  font-size: 12px;
}

.save-display-btn {
  background-color: #7071e8 !important;
}

.cancel--button {
  font-size: 20px;
  color: red;
  margin-bottom: 30px;
}

.save-display {
  background-color: rgb(152, 172, 152) !important;
  border-radius: 2px;
  font-size: 12px;
}

custom-modal-size .savebtn-contaier {
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  display: flex;
  margin: 0 auto;
}

.save-icon {
  margin-bottom: 3px;
}

.preview-search-input-container-lay {
  width: 25%;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 4px;
  padding-left: 7px;
  gap: 1px;
  height: 30px;
  box-shadow: 0 0 4px #ccc;
  border-radius: 3px;
  margin-left: 25px;
}

.preview-add-layout-search-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

/* .custom-modal-size .modal-dialog {} */
.xbtn {
  border: 0px solid #d9d9d9;
  top: 35px;
  left: 95%;
  background: transparent;
  color: #a88787;
  position: relative;
}

.monitor-image-container {
  background-image: url("../../assets/636046350508172074-tv-isolated-background-clipart-removebg-preview.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0.8rem;
  padding-top: 0.7rem;
  width: 90%;
}

.image-display {
  max-height: 300px;
  width: 580px;
}

.tv-btn-box {
  position: relative;
  width: 700px;
  gap: 10px;
  display: flex;
}
.custom-tv-btn {
  color: #0a58cc;
  font-size: 22px;
  background-color: transparent;
  border: none;
  padding: 0px 5px 12px 7px;
}
.red-btn {
  color: red;
}
.custom-tv-btn:hover {
  background-color: transparent;
  color: #0a58cc;
}
.all-button-container {
  background-color: #d2d2d2;
  width: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}
.green-btn {
  color: green;
}

/* Mobile responsiveness */

@media only screen and (max-width: 767.98px) {
  .displays-bg-container {
    background-color: #f5f7f8;
    width: 100%;
    padding: 5px;
    min-height: 100vh;
    overflow-y: auto !important;
  }

  .tv-btn-box {
    position: relative;
    width: 250px;
    gap: 5px;
    display: flex;
  }

  /* .all-button-container {
    background-color: #d2d2d2;
    width: 5%;
    height: 124px;
    display: flex;
    flex-direction: column;
    gap: 0;
  } */

  .monitor-image-container {
    background-image: url("../../assets/636046350508172074-tv-isolated-background-clipart-removebg-preview.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 240px;
    padding-left: 0.35rem;
    padding-right: 0.3rem;
    padding-top: 0.2rem;
    width: 90%;
  }

  .image-display {
    max-height: 114px;
    width: 356px;
  }

  .card-img-top {
    height: 115px;
    margin-bottom: 10px;
    width: 100%;
  }

  .custom-tv-btn {
    font-size: 10px;
    background-color: transparent;
    border: none;
    padding: 0px 0px 8px 1px;
  }

  .button-container {
    height: 17px;
  }
}
@media screen and (max-width: 768px) {
  .grid-display-card {
    /* Adjust styles for smaller screens */
    /* For example: */
    width: 100%;
  }
}
@media (max-width: 640px) {
  .tw-sm\:grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (min-width: 641px) and (max-width: 768px) {
  .tw-md\:grid-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 769px) and (max-width: 1110px) {
  .tw-lg\:grid-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1111px) and (max-width: 2000px) {
  .tw-lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media screen and (max-width: 767px) {
  /* Apply styles for screens smaller than 768px (mobile devices) */
  .display-pop-container {
    height: 400px;
  }
}
.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search-container {
  display: flex;
  flex-wrap: wrap;
}

.view-buttons {
  display: flex;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
  }

  .view-buttons {
    margin-top: 20px;
  }
}

.unselected::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 125%;
  height: 1px;
  color: red;
  background-color: currentColor;
  transform: translate(-50%, -50%) rotate(135deg);
  transform-origin: center;
}
.date-input-container {
  position: relative;
  width: 100%;
}

.existing-date-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
}

.date-text {
  flex-grow: 1;
}

.edit-icon {
  margin-left: 8px;
  color: #1890ff;
  opacity: 0.7;
}

.existing-date-display:hover .edit-icon {
  opacity: 1;
}
