@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  pointer-events: none;
  user-select: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  display: none;
} */

.css-c55nm8-MuiFormControl-root {
  width: 100% !important;
  margin: 0px !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  left: 0px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important ;
  border-radius: 0px !important;
}

.css-1rz1w75-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  padding: 0px !important;
  min-height: 60px !important;
  padding-left: 10px !important;
}

.css-1fo4dxe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  display: flex;
  align-items: center;
  min-height: 40px !important;
}

.css-cw8oh3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  padding: 0px !important;
  min-height: 60px !important;
  padding-left: 10px !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
  border-radius: 10px !important;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  padding: 10px !important;
  background-color: #f8f9fd !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  background-color: transparent;
  /* box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05); */
}

.MuiList-padding {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) in !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-image: linear-gradient(to bottom, #1c70ed, #0645a4) !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #fff;
  font-family: "poppins";
  height: 80px !important;
}

.css-1km1ehz.Mui-selected {
  background-image: linear-gradient(to bottom, #1c70ed, #0645a4) !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #fff;
  font-family: "poppins";
  height: 80px !important;
}

.css-r8u8y9 {
  padding: 0px !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  background-color: #fff !important;
  border-bottom: 1px solid #ccc;
  border-radius: 6px;
}

.css-pwxzbm {
  padding: 8px !important;
}

.css-1316eg8 {
  padding: 0px !important;
  min-height: 60px !important;
  padding-left: 10px !important;
}
