.layouts-bg-container {
  background-color: #F5F7F8;
  width: 100%;
  padding: 8px;
  min-height: 100vh;
}

.parabeloww-search {
  color: #132043;
  font-size: 12px;
  line-height: 0px;
}

.layouts-of-texas {
  display: flex;
  align-items: center;

  justify-content: space-between;
  margin-bottom: 10px;
}

.actives {
  background-color: green;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.paddingleftten {
  text-align: left !important;
  padding-left: 10px !important;
}

.center-head {
  text-align: center !important;
}

th {
  background-color: #f0f0f0;
  text-align: left;
  padding: 8px;
}

td {
  padding: 8px;

}

thead th[colspan="6"] {
  text-align: center;
  padding: 12px 0;
  background-color: #ffffff;
  border-bottom: 2px solid #ddd;
}

.name-list-table {
  display: flex;
  align-items: center;
  gap: 5px;
}

.name-list-table h6 {
  margin: 0;
  line-height: 1.1;
  font-size: 14px;
}

.name-list-table p {
  margin: 0;
  line-height: 1.1;
  font-size: 12px;
}

.listpictures {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.lastupdate {
  display: flex;
  align-items: center;
  gap: 5px;
}

.lastupdate h6 {
  margin: 0;
  line-height: 1.1;
  font-size: 14px;
}

.lastupdate p {
  margin: 0;
  line-height: 1.1;
  font-size: 12px;
}

.top-btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.centered-button {
  background-color: #F5F7F8;
  border: 1px solid #F5F7F8;
  display: flex;
  align-items: center;

  border-radius: 2px;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.deletee-button {
  color: red;
}

.view-button {
  color: #2946d7;
}

.deletee-button:hover {
  background-color: #ccc;
  color: red;
}

.view-button:hover {
  background-color: #ccc;

  color: blue;
}

.modal-layout {
  padding: 10px 20px;
}

.example-parent {
  display: flex;
  flex-wrap: wrap;
}

.bdr {
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}

.aspect-ratio-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.cardd-img-top {
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.example-parent {
  display: flex;
  flex-wrap: wrap;
}

.bdr {
  border-radius: 5px;
  overflow: hidden;
}

.react-player-wrapper {
  position: relative;
  padding-top: 50.25% !important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.status-filter-btn {
  background-color: #fff;
  width: 180px;
  border-radius: 3px;
  /* margin-bottom: 13px; */
  color: #BFBFBF;
  font-family: "Open Sans";
  display: flex;
  border: 1px solid #ccc;
  font-family: "poppins";
  justify-content: space-between;
  transition: transform 0.3s ease;
  align-items: center;
  letter-spacing: 0.6px;
  box-shadow: 0 0 4px #ccc;
  /* font-weight: bold; */
  height: 30px;
}

.status-filter-btn:hover {
  color: #000 !important;
  border: 1px solid #ccc !important;
}

.search-input-dropdown-containers{
  display: flex;
  gap: 9px;
  justify-content: right;
}
.search-input-dropdown-container{
  display: flex;
  gap: 20px;
  margin: 25px;
  justify-content: flex-end;
}


.add-layout-search-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.search-input-container-lay {
  width: 54%;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 4px;
  padding-left: 7px;
  gap: 1px;
  height: 30px;
  /* margin-bottom: 15px; */
  box-shadow: 0 0 4px #ccc;
  border-radius: 3px;
}

/* .list-layout{
  font-size: 14px !important;
  border-radius: 3px;
  text-align: center;
  justify-content: center;
  width: 130px;
  background-color: #fff !important;
  padding: 5px;
  color: black;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 3px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 14px;
}
.list-layout:hover {
  background-color: rgb(8, 95, 188) !important;
} */