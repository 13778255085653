.search-input-containers {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 4px 4px 4px 7px;
  gap: 5px;
  height: 30px;
  box-shadow: 0 0 4px #ccc;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .search-input-containers {
    margin-top: -180px;
    width: 46%;
  }
}

@media (max-width: 767px) {
  .search-input-containers {
    margin-top: -308px;
    width: 80%;
  }
}

.search-inputs {
  border: none;
  outline: none;
  width: 100%;
  max-width: 230px;
  height: 30px;
  background-color: transparent;
}

.custom-dropdown .ant-dropdown-menu {
  margin-top: -25px;
}
