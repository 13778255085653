@media (max-width: 640px) {
    .tw-sm\:grid-cols-2 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  
  @media (min-width: 641px) and (max-width: 768px) {
    .tw-md\:grid-cols-3 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .tw-lg\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }