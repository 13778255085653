.poppins-font {
  font-family: "Poppins", sans-serif;
}
.library-bg-container {
    background-color: #f5f7f8;
    width: 100%;
    padding: 8px;
    min-height: 100vh;
  }
  
  .texas-chicken-container {
    display: flex;
    align-items: center;
  
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .add-more-btn {
    margin-right: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.541);
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 15%;
  }
  
  .images-btn {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding-bottom: 5px;
    border-radius: 0px;
    width: 110px;
    color: #313131;
  }
  
  .images-btn-border {
    border-bottom: 1px solid #a1a2a9;
    color: #000000;
  }
  
  .images-btn-active {
    border-bottom: 1px solid blue;
  }
  
  .images-videos-btn-container {
    border-bottom: 1px solid #e9e8e8;
  }
  
  .search-input-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 4px 4px 4px 7px;
    gap: 5px;
    height: 30px;
    z-index: 1;
    box-shadow: 0 0 4px #ccc;
    border-radius: 3px;
  }
  
  @media (max-width: 768px) {
    .search-input-container {
      width: 26%;
      justify-content: end;
      margin-left: 742px;
      margin-bottom: 100px;
      margin-top: -124px;
    }
  }
  
  @media (max-width: 767px) {
    .search-input-container {
      width: 80%;
      margin: 20px auto; 
      justify-content: center;
    }
  }
  
  
  .search-input {
    border: none;
    outline: none;
    width: 350px;
    height: 30px;
    background-color: transparent;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .single-image-preview-container {
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-preview-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .image-preview {
    border: 1px solid #ccc;
    width: 9rem;
    height: 6rem;
    border-radius: 13px;
  }
  
  .video-grid-container {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .video-grid-item {
    width: 100%;
  }
  
  .image-preview-container {
    position: relative;
    display: inline-table;
    margin: 4px; /* Adjust margin as needed */
  }
  

  .file-name {
    position: absolute;
    bottom: -26px;
    left: 0;
    width: 100%;
    color: black; 
    font-size: 12px;
    padding: 4px 8px; 
    box-sizing: border-box;
    border-bottom-left-radius: 13px; 
    border-bottom-right-radius: 13px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
  }
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #ccc;
    border: 1px solid #000;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    padding: 0;
    margin: 0;
    line-height: 1;
    padding-right: 2px;
    padding-left: 2px;
  }
  
  .close-button:hover {
    background-color: #000;
    color: white;
    border: 1px solid #ccc;
  }
  @media (max-width: 768px) {
    .images-videos-btn-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    /* .images-videos-btn-container > div {
      width: 100%;
    } */
  }
  