.App {
  text-align: center;
}

body {
  font-family: "Poppins";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bgbtn {
  --amplify-internal-button-background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
}

.amplify-button[data-variation="primary"] {
  background: linear-gradient(
    to right,
    var(--amplify-colors-green-80),
    var(--amplify-colors-orange-40)
  );
}

.amplify-button--primary {
  --amplify-internal-button-background-color: rgb(0, 82, 204) !important;
}

.amplify-tabs__item {
  display: none !important;
}

.amplify-tabs__list {
  border-color: white !important;
}

.amplify-tabs body {
  color: white !important;
}

.amplify-button
  amplify-field-group__control
  amplify-button--primary
  amplify-button--fullwidth {
  background-color: yellow !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: transparent !important;
  border-width: var(--amplify-components-authenticator-router-border-width);
  border-style: none;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 32px 32px 10px 32px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.loginbgpic {
  /* background-image: url(./assets/FA45B89F-CE87-4057-8537-AB9024E6358B_1_201_a.jpeg); */
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  margin: 0;
  display: flex;
  flex: 1;
  background-size: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#root {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  flex: 0 0 225px;
  height: 100%;
  overflow-y: hidden;
  transition: all 0.3s ease;
}
.mobile-sidebar{
  background-color:white;
  position: fixed;
  z-index: 1000;
  width: 56%;
}

.menu-icon {
  display: none;
}

/* @media screen and (max-width: 767px) {
  .sidebar {
    flex: 0 0 80px;
  }

  .sidebar.open {
    flex: 0 0 150px;
  }

  .menu-list a .text {
    display: none;
  }

  .sidebar.open .menu-list a .text {
    display: block;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu-list.active-pro {
    display: block;
  }

  .menu-list:not(.active-pro) {
    display: none;
  }

  .sidebar.open .menu-list:not(.active-pro) {
    display: block;
  }

  .sidebar:not(.open) .menu-list {
    display: none;
  }
} */


@media screen and (max-width:480px) {
  .sing-up-res {
    padding-top: 0px !important;
  }

  .loginbgpic {
    display: none !important;
  }
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 2% 1%;
  background-color: #f4f6f8;
}

.userIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.logo-img {
  margin-top: 8%;
}

li,
ol {
  list-style: none;
}

.menu-list a {
  padding: 0px;
  display: flex;
  color: black;
  /* align-items: center; */
}

.icon {
  margin-right: 10px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.quick-reply-container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

.container {
  background-color: #f8f9fa !important;
  height: 100vh !important;
  overflow: scroll !important;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error {
  color: red;
  font-size: smaller;
  text-transform: capitalize;
}

.statusTab {
  align-items: center;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 23px;
  justify-content: center;
  text-align: center;
  width: 91px;
}

.deactive {
  background-color: red;
}

.active {
  background-color: green;
}

.rdt_TableHead,
/* .rdt_TableHeadRow {
  background: #cdcdcd !important;
} */
.erNLGs {
  border-bottom-color: transparent !important;
}

.rdt_TableRow {
  background: #f9f9f9 !important;
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .sidebar {
    flex: 0 0 245px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
  .sidebar {
    flex: 0 0 265px;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2100px) {
  .sidebar {
    flex: 0 0 285px;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2500px) {
  .sidebar {
    flex: 0 0 325px;
  }
}


.chatbot-container::-webkit-scrollbar {
  width: 8px;
}
.chatbot-container::-webkit-scrollbar {
  height: 0px;
}
.chatbot-container::-webkit-scrollbar-thumb {
  background-color: rgb(65, 84, 168); 
  border-radius: 8px; 
}

.chatbot-container {
  scroll-behavior: smooth;
}

