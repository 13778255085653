/* .sidebar {
    position: fixed;
    top: 0;
    left: -250px; 
    width: 250px;
    height: 100%;
    background-color: #f5f7f8;
    transition: left 0.3s ease;
  } */

  .open {
    left: 0; 
   
  }
  
  .mobilezindexxxx{
    z-index: 200;
  }
  .menu-list {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 40px;
  }
  
  .menu-list.selected {
    background-color: #0d6efd;
    color: #fff;
    border-radius: 7px;
    padding: 0 40px;
    height: 40px;
  }
  
  .menu-list.selected .text {
    
    /* color: #fff; */
  }
  
  .menu-list.selected .icon {
   
    color: #fff;
  }
  
  .sidebar-item {
    display: flex;
    align-items: right;
    margin-left: 15px;
  }
  
  .sidebar-item p.sidebar-text {
    margin-left: 15px;
  }

  .account-footer{
    max-width: 245px !important;
  }
