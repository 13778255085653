.add-layout-buttons {
    font-size: 14px !important;
    color: rgb(101, 89, 160);
    border-radius: 3px;
    text-align: center;
    justify-content: center;
    width: 130px;
    background-color: #0d6efd !important;
    padding: 7px;
    color: #fff;
    display: flex;
    align-items: center;
    height: 30px;
    gap: 3px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* font-weight: bold; */
    font-size: 14px;
  }
  .add-layout-buttons:hover {
    background-color: rgb(8, 95, 188) !important;
  }

  .activve{
    background-color: green;
  }