.dashboard-bg-container {
    background-color: #f3f3f3f0;
    min-height: 100vh;
    padding: 10px;
}

.dashboard-list-container {
    list-style-type: none;
    padding-left: 20px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.contents {
    flex: 1 1;
    overflow-y: auto;
   
    background-color: #f4f6f8;
}
.dashboard-list-item-container {
    width: 24%;
    box-shadow: 0 0 0px #888888;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    padding: 13px;
    border-radius: 3px;
    font-size: 13px;
    font-family: "Roboto Mono";
    justify-content: space-between;
    background-color: #ffffff;
}

.dashboard-list-item-container-last {
    width: 24%;
    box-shadow: 0 0 0px #888888;
    border: 1px solid 3D30A2;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 13px;
    border-radius: 3px;
    justify-content: space-between;
    background-color: #3D30A2;
}
.top-wordings h3, 
.top-wordings p{
    margin: 0px;
}
.stores-para {
    margin: 0px;
    margin-bottom: 9px;
}

.store-icon {
    font-size: 30px;
    background-color: rgb(10, 108, 255);
    padding: 6px;
    color: #fff;
    border-radius: 50%;
}

.image-icon {
    font-size: 30px;
    background-color: rgb(255, 255, 255);
    padding: 6px;
    color: #0f0f0f;
    border-radius: 50%;
}

.prush-icon {
    font-size: 30px;
    background-color: rgb(72, 179, 82);
    padding: 6px;
    color: #fff;
    border-radius: 50%;
}

.display-icon {
    font-size: 30px;
    background-color: rgb(229, 172, 16);
    padding: 8px;
    color: #fff;
    border-radius: 50%;
}

.stores-heading {
    margin: 0px;
    margin-top: 5px;
    font-size: 18px;
}

.stores-span {
    border: 1px solid #818181;
    color: #000000;
    font-size: 11px;
    /* box-shadow: 0 0 2px #959494; */
    padding: 1px;
    border-radius: 2px;
    margin-left: 5px;
}

.image-span {
    border: 1px solid #ffffff;
    color: #fff;
    font-size: 11px;
    border-radius: 2px;
    padding: 1px;
    margin-left: 5px;
    /* box-shadow: 0 0 3px #fff; */
}

.states-layout-container {
    display: flex;
    padding-left: 20px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.display-chart-container {
    height: 310px;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #ffffff;
}

.layout-chart-container {
    height: 310px;
    width: 30%;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color:  #ffffff;
}

.select-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #ccc;
    padding: 2px;
    cursor: pointer;
    font-size: 12px;
    margin-right: 13px;
}
.layout-chart-heading{
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 6px;
}
.layout-section-div{
    display: flex;
    padding-left: 20px;
    gap: 18px;
    margin-top: 20px;
    align-items: flex-start;
}
.mostused-layout-heading{
    padding-left: 20px;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
    padding-bottom: 10px;
}
.marginzero{
    margin: 0px;
}
.fs-weleven{font-size: 11px;}
.fs-fteen{
    font-size: 14px;
}
.mostused-layout-lists {
    line-height: 0px;
    margin-right: 5px;
}
.seel-all-text{
    align-items: center;
    text-align: end;
    padding:0px 10px 15px 10px;
}
.store-section-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 5px;
}
.store-sec-heading{
    margin: 0px 0px 0px 20px;
}
.store-register-sec{
    display: flex;
    text-align: center;
    justify-content: center;
}
.heading-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 7px;
}

.state-heading {
    margin-left: 13px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.layout-list-container {
    display: flex;
    list-style-type: none;
    gap: 30px;
    padding-left: 0px;
    justify-content: center;
}

.layout-list-item-container {
    text-align: center;
    /* border-right: 1px solid #ccc; */
}

.layout-section {
    border: 1px solid #ccc;
    width: 60%;
    background-color: #ffffff;
    box-sizing: border-box; /* This ensures border is included in the width */
}

.store-section {
    border: 1px solid #ccc;
    width: 40%;
    background-color: #ffffff;
    box-sizing: border-box; /* This ensures border is included in the width */
}


.layout-unorder-list {
    list-style-type: none;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    border-bottom: 1px solid #ccc;
    padding: 0px 20px 0px 20px;
    align-items: center;
}

.layout-list-image {
    width: 50px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 10px !important;
    margin: 0px;
    margin-bottom: 10px !important;
    flex-shrink: 0;
}

.layout-unorder-list li {
    flex-grow: 1;
    /* max-width: calc(33.33% - 10px); */
    box-sizing: border-box;
}
.circular-progressbar {
    height: 50px;
    width: 50px;
    margin:0px 0px  10px 5px; 
}
.store-image {
    height: 45px;
    width: 45px;
    margin-top: -5px;
    border: 1px solid #ccc;
    border-radius: 50%;
}
.store-unorderlist h5{
 font-size: 16px;   
}
.store-unorderlist {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: small;
    align-items: center;
    padding: 0px 20px 0px 20px;
    border-bottom: 1px solid #ccc;
    margin-top: 0;
    margin-bottom: 0;
}
.store-display {
    font-size: small;
    padding: 0px 20px 0px 20px;
}
.borderstore{
    border-right: 1px solid #ccc;
}
.mg-topzero{
    margin-top: 0px;
}
.mg-btmzero{
    margin-bottom: 0px;
}
.txt-align-end{
    text-align: end;
}
.sc-dlfmHC.cfvlby.rdt_TableHeadRow {
    border-bottom: none;
    border-radius: 5px;
}
