.parabelow-search {
  color: #132043;
  line-height: 0px;
}

.library-container {
  padding: 15px;
  margin: 0;

  background-color: #f8f8f8;
}
.videos-container{
  margin: 20px;
}
.loader {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.video-grid {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

.widthh {
  width: calc(32% - 20px);
}

.search-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.search-input {
  margin-left: 8px;
}

.video-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.video-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}

@media screen and (max-width: 767px) {
  .video-card {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 16px 0;
  }
}

.react-player-wrapper {
  position: relative;
  padding-top: 56.25% !important;
}

.contentt-below-pic {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: row !important;
  padding: 0px 5px 10px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contentin-image-container {
  display: flex;
  justify-content: space-between;
  grid-area: 10px;
}

.video-info {
  padding: 10px;

  background: #f9f9f9;
}

.parabelow-video p {
  margin: 0;
}

.video-title {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* In case the title is too long */
}

.video-meta {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* In case the meta is too long */
}

.video-stats {
  font-size: 0.8rem;
  color: #000000;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Wrap stats if space is not enough */
}

.fntweight400 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
}

.video-size,
.video-usage,
.video-type {
  padding: 2px 8px;
  border-radius: 12px;
  margin: 2px 0;
  /* Ensure spacing if wrapped */
}
.creste-size-desc {
  color: rgba(0, 0, 0, 0.45);
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
