.profile-card {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 370px;
    width: 100%;
    background: #fff ;
    border-radius: 24px;
    padding: 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}
.marginbtm{
    outline: none;
    border: 0px;
    margin-bottom: 10px;
}
.edit-profile-pic{
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
}
.modal {
    --bs-modal-padding: 10px;
}
.profile-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 36%;
    width: 100%;
    border-radius: 24px 24px 0 0;
    background-color: #4070f4;
}

.image {
    position: relative;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: #4070f4;
    padding: 3px;
    margin-bottom: 10px;
}

.image .profile-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
}

.profile-card .text-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
}

.text-data .name {
    font-size: 22px;
    font-weight: 500;
}

.text-data .job {
    font-size: 15px;
    font-weight: 400;
}

.profile-card .media-buttons {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.media-buttons .link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    margin: 0 8px;
    background-color: #4070f4;
    text-decoration: none;
}

.profile-card .buttons {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.buttons .button {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border: none;
    border-radius: 24px;
    margin: 0 10px;
    padding: 8px 24px;
    background-color: #4070f4;
    cursor: pointer;
    transition: all 0.3s ease;
}

.buttons .button:hover {
    background-color: #0e4bf1;
}

.profile-card .analytics {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.analytics .data {
    display: flex;
    align-items: center;
    color: #333;
    padding: 0 20px;
    border-right: 2px solid #e7e7e7;
}

.data i {
    font-size: 18px;
    margin-right: 6px;
}

.data:last-child {
    border-right: none;
}

.mainusers{
    background-color: #f5f7f8;
    padding-left: 30px;
    padding-top: 30px;
}
