.library-bg-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.images-container {
  margin: 20px;
}

.search-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.search-input {
  margin-left: 8px;
}

.parabelo-searchh {
  font-size: 18px;
  margin-bottom: 16px;
}

.images-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  flex: 0 0 calc(33.3333% - 32px);
  max-width: calc(33.3333% - 32px);
  margin: 16px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .card {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 16px 0;
  }

  .search-input {
    font-size: 16px;
    width: 100%;
    margin: 8px 0;
  }

  .search-input-container {
    margin-bottom: 8px;
    width: 100%;
  }
}

.creste-size-desc {
  font-size: 13px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.card-cover {
  flex: 1;
}
.ant-card-body {
  padding: 10px 10px 0px 10px !important;
  line-height: normal !important;
}
/* .food-image {
  width: 100%;
  height: 0;
  padding-top: 56.25%; 
  object-fit: cover;
} */

.content-below-pic {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.date-desc-container {
  width: 100%;
}

.mb-1 {
  margin-bottom: 1rem;
}

.create-desc {
  font-size: 14px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ant-pagination-item {
  border-radius: 50%;
}

.active {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #fff;
}

.mt-3 {
  margin-top: 24px;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.pagination li a {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
}

/* Style for the active page number */
.pagination li.active a {
  border-color: #007bff;
  background-color: #c2ccd7;
  color: white;
}

/* Hover styles */
.pagination li a:hover {
  background-color: #e9e9e9;
}

/* Previous and Next buttons */
.pagination li.previous a,
.pagination li.next a {
  font-weight: bold;
}

/* Disabled Previous and Next buttons */
.pagination li.disabled a {
  color: #cccccc;
}

.skeleton-transition {
  transition: background-position 1s ease-in-out;
}

.shimmer-loading {
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
    mask-position: left;
  }
}

.avatar-container {
  display: inline-block;
}
