.layoutview-container {
  background-color: #F4F6F7;
  width: 100%;
  padding: 20px;
  height: "100%";
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  
  /* justify-content: space-between; */
 
  
  min-height: 100vh;

  overflow-y: auto !important;
}

.searchbar-top {
  display: flex;

  gap: 5px;
} 

.design-layout-of-texas {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gapright {
  margin-right: 10px;
}

/* images contaenrrr */
.react-video-wrapperrr {
  position: relative;
  padding-top: 40.25%;
}
.custom-col {
  flex-basis: 33%;
  max-width: 80%;
  height: 119px;
}
.large-image-container {
  background-color: white;
  padding: 10px 40px;
  text-align: center;
  justify-content: center;
}

.large-burger {
  width: 85%;
  height: 400px;
  object-fit: cover;
  margin: 0 auto;

  background-color: white;
}

.images-selection {
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 7px;
  border: dashed #a9a9a9;
  padding: 7px;
  min-height: 110px;
  display: flex;
  background-color: #f0f0f0;
  justify-content: left;
  /* gap: 10px; */
  flex-wrap: wrap;
}

.image-container {
  position: relative;
  /* display: inline-block */
}

.paddinglr {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.paddingtop {
  padding-top: 20px;
  padding-bottom: 0px;
  display: flex;
  justify-content: end;
}

.small-burger {
  width: 100px;
  cursor: pointer;
  height: 90px !important;
}

.close-btn {
  padding: 0px 5px !important;
  font-size: 16px !important;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  background-color: transparent !important;
  border: none;
  cursor: pointer;
  color: white;
}

.close-btn :hover {
  background-color: black !important;
}

.image-container:hover .close-btn {
  display: block;
}

/* sidebar selection  */

.layout-content {
  display: flex;
  gap: 20px;
 flex-direction: column;
  background-color: white;
}

.image-video{
  display: flex;
  gap: 5px;
 flex-direction: row;
 /* justify-content: space-between; */
  background-color: white;
  

}

.sidebar-in-layouts {
  background-color: rgb(224, 224, 224);
  width: 150px;
  flex-shrink: 0;
}

.sidebar-in-layouts h5 {
  background-color: rgb(224, 224, 224);
  text-align: left;
  font-size: 18px;
  border-bottom: 1px solid #000;
  color: #000;
  padding: 10px 0px 10px 10px;
}

.library-content-in-layouts {
  flex-grow: 1;
}

.layout-content ul {
  list-style-type: none;
  padding: 0;
}

/* .cards {
    flex: 0 0 calc(70.3333% - 50px); 
    max-width: calc(70.3333% - 16px);
    margin: 16px;
    display: flex;
    flex-direction: column;
  } */
.side-links {
  cursor: pointer;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: 8px 16px;
  gap: 6px;
  /* border-bottom: 1px solid #a6aab2; */
  transition: background-color 0.3s ease;
}

.imageselection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 0px;
  padding-right: 20px;
  width: 100%;
}

.videoSelection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 0px;
  padding-right: 20px;
}

.media-item {
  border-radius: 5px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 119px;
  object-fit: cover;
}
/* 
.dropped-item {
  flex-grow: 1;
  max-width: 0 0 calc(10% - 20px)
} */

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 999;
}

.carousel-item-img {
  width: 80%;
  height: auto;
  /* max-height: 100%; */
}

.carousel-item-video {
  /* width: 100%; */
  /* background-size: cover; */
  background: black;
  height: auto;
  max-height: 510px;
}

.carousel {
  width: 100%;
}

.media-content {
  text-align: center;
}

.carousel-item-imgg {
  width: 700px;
  height: auto;
  /* max-height: 400px;  */
}

.activve {
  background-color: green;
}

.active {
  background-color: white !important;
}

.carousel-item-videooo {
  width: 700px;
  background-size: contain;
  height: auto;
  max-height: 350px;
}

.carousel-item-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
}

.hide-resize-observer {
  display: none !important;
}

.save-layout-button {
  color: rgb(101, 89, 160);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  width: 130px;
  background-color: #0d6efd !important;
  padding: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 3px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* font-weight: bold; */
  font-size: 14px !important;
}

.save-layout-button:hover {
  background-color: rgb(8, 95, 188) !important;
}

.discard-btn {
  color: rgb(101, 89, 160);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  width: 110px;
  background-color: #6c757d !important;
  padding: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 3px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* font-weight: bold; */
  font-size: 14px !important;
  border: none;
  margin-right: 13px;
}

.discard-btn:hover {
  background-color: #596065 !important;
}

.layoutview-container::-webkit-scrollbar-track {
  background: transparent;
}

.layoutview-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
}

.layoutview-container::-webkit-scrollbar {
  width: 8px;
}

.layout-img-container {
  width: 100%;
  height: 378px ;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1224px) {
  .carousel-item-imgg {
    width: 649px;
    max-height: 283px;
  }
  .carousel-item-img {
    max-height: 430px;
  }
}

@media screen and (min-width: 1350px) {
  .carousel-item-imgg {
    width: 784px;
    max-height: 363px;
  }
  .carousel-item-img {
    max-height: 441px;
  }
}

@media screen and (min-width: 2000px) {
  .carousel-item-imgg {
    width: 1290px;
    max-height: 711px;
  }
  .carousel-item-img {
    width: 1702px;
    max-height: 842px;
  }
  .images-selection {
    min-height: 180px;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
  }
  .custom-col {
    height: 200px; /* Example: Adjusted height */
  }

  .media-item {
    height: 188px;
  }
  .sidebar-in-layouts {
    width: 185px;
  }
  .layout-img-container {
    height: 616px;
  }
}

@media screen and (min-width: 3000px) {
  .carousel-item-imgg {
    width: 2391px;
    max-height: 1171px;
  }
  .carousel-item-img {
    width: 2582px;
    max-height: 1302px;
  }
  .layout-img-container {
    height: 879px;
  }
  .custom-col {
    height: 370px; /* Example: Adjusted height */
  }
  .media-item {
    height: 395px;
  }
  .sidebar-in-layouts {
    width: 240px;
  }
}

/* Responsive Font Size */
.responsive-font {
  font-size: 18px;
}

@media (max-width: 575.98px) {
  .responsive-font {
    font-size: 16px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .responsive-font {
    font-size: 17px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .responsive-font {
    font-size: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .responsive-font {
    font-size: 19px;
  }
}

@media (min-width: 1200px) {
  .responsive-font {
    font-size: 20px;
  }
}

/* Mobile View Styles */
@media only screen and (max-width: 767.98px) {
  .layoutview-container {
    padding: 8px;
  }

  .searchbar-top {
    flex-direction: column;
  }

  .design-layout-of-texas {
    flex-direction: column;
    align-items: flex-start;
  }

  .save-layout-button {
    margin-left: 10px;
  }

  .large-image-container {
    padding: 10px;
  }

  .custom-col {
    flex-basis: 100%;
    max-width: 50%;
    height: auto;
    margin-bottom: 10px;
  }

  .images-selection {
    flex-direction: row;
    align-items: center;
    padding: 10px;
    min-height: auto;
  }

  .sidebar-in-layouts {
    width: 100%;
  }

  .layout-content {
    flex-direction: column;
    gap: 10px;
  }

  .library-content-in-layouts {
    width: 100%;
  }

  .responsive-font {
    font-size: 16px;
  }

  .save-layout-button,
  .discard-btn {
    width: 100%;
    margin-right: 0;
  }

  .carousel-item-imgg {
    width: 100%;
    max-height: 200px;
  }

  .carousel-item-img {
    max-height: 250px;
  }

  .layout-img-container {
    height: 200px;
  }

  .media-item {
    height: 100px;
  }
}